<template>
  <div class="row align-items-center w-100">
    <div class="col ps-xxl-5">
      <div class="text-start fs-1 fw-bold my-shadow">
        Здравствуйте,
      </div>
      <div class="text-start fs-2 fw-bolder my-shadow">
        Я начинающий web-программист.
      </div>
      <div class="text-start fs-4 fst-italic">
        Это приложение написано с применением технологии:
      </div>
      <div class="text-start fs-4 fst-italic">
        JavaScript-фреймворка - Vue-3,
      </div>
      <div class="text-start fs-4 fst-italic">
        Маршрутизатора - Vue-router,
      </div>
      <div class="text-start fs-4 fst-italic">
        Библиотекой для управления состоянием - Vuex.
      </div>
    </div>
    <div class="col d-flex justify-content-start">
      <img class="w-50 h-50 border rounded-pill" src="images/programmer-typing-data-code-on-computer-for-software-development-vector.jpg" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeMy"
}
</script>

<style>
.my-shadow {
  text-shadow: 3px 3px 4px rgba(163,164,166, 0.9);
}
</style>