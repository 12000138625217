<template>
  <div>AnimationCSS</div>
</template>

<script>
export default {
  name: "AnimationCSS"
}
</script>

<style scoped>

</style>