<template>
  <form class="form-my">
    <div class="mb-3">
      <label for="exampleInputFirstName" class="form-label">Введите имя</label>
      <input v-model="firstName" type="text" class="form-control" :class="{'is-valid-color': isFirstName, 'is-un-valid-color': !isFirstName }" id="exampleInputFirstName" aria-describedby="firstNameHelp">
      <div id="firstNameHelp" class="form-text" :class="{'text-un-valid': !isFirstName}">{{isFirstName ? 'Поле заполнено правильно' : 'Введите ваше имя!'}}</div>
    </div>
    <div class="mb-3">
      <label for="exampleInputLastName" class="form-label">Введите фамилию</label>
      <input v-model="lastName" type="text" class="form-control" :class="{'is-valid-color': isLastName, 'is-un-valid-color': !isLastName }" id="exampleInputLastName" aria-describedby="lastNameHelp">
      <div id="lastNameHelp" class="form-text" :class="{'text-un-valid': !isLastName}">{{isLastName ? 'Поле заполнено правильно' : 'Введите вашу фамилию!'}}</div>
    </div>
    <div class="mb-3">
      <label for="exampleInputEmail" class="form-label">Введите Email</label>
      <input v-model="emailUser" type="email" class="form-control" :class="{'is-valid-color': isEmailUser, 'is-un-valid-color': !isEmailUser }" id="exampleInputEmail" aria-describedby="emailHelp">
      <div id="emailHelp" class="form-text" :class="{'text-un-valid': !isEmailUser}">{{isEmailUser ? 'Поле заполнено правильно' : 'Введите ваш Email!'}}</div>
    </div>
    <div class="mb-3">
      <label for="exampleInputPassword1" class="form-label">Придумайте пароль</label>
      <input v-model="password" type="password" class="form-control" :class="{'is-valid-color': isPassword, 'is-un-valid-color': !isPassword }" id="exampleInputPassword1" aria-describedby="Password1Help">
      <div id="Password1Help" class="form-text" :class="{'text-un-valid': !isPassword}">{{isPassword ? 'Поле заполнено правильно' : 'Длина пароля должна быть больше 5'}}</div>
    </div>
    <div class="mb-3">
      <label for="exampleInputPassword2" class="form-label">Повторите пароль</label>
      <input v-model="password2" type="password" class="form-control" :class="{'is-valid-color': isPassword2, 'is-un-valid-color': !isPassword2 }" id="exampleInputPassword2"  aria-describedby="Password2Help">
      <div id="Password2Help" class="form-text" :class="{'text-un-valid': !isPassword2}">{{isPassword2 ? 'Поле заполнено правильно' : 'Пароль должен совпадать'}}</div>
    </div>
    <button :disabled="isDisabled" class="btn btn-primary">Submit</button>
  </form>
</template>

<script>
export default {
  name: "RegistrationUser",
  data() {
    return {
      firstName: '',
      lastName: '',
      emailUser: '',
      password: '',
      password2: '',
      isFirstName: false,
      isLastName: false,
      isEmailUser: false,
      isPassword: false,
      isPassword2: false,
      isDisabled: true,
    }
  },
  methods: {

  },
  watch: {
    firstName(newVal) {
      this.isFirstName = newVal.length > 2
      this.isDisabled = !(this.isFirstName && this.isLastName && this.isEmailUser && this.isPassword && this.isPassword2)
    },
    lastName(newVal) {
      this.isLastName = newVal.length > 2
      this.isDisabled = !(this.isFirstName && this.isLastName && this.isEmailUser && this.isPassword && this.isPassword2)
    },
    emailUser(newVal) {
      this.isEmailUser = newVal.includes('@')
      this.isDisabled = !(this.isFirstName && this.isLastName && this.isEmailUser && this.isPassword && this.isPassword2)
    },
    password(newVal) {
      this.isPassword = newVal.length > 5
      this.isPassword2 = this.password2 === newVal
      this.isDisabled = !(this.isFirstName && this.isLastName && this.isEmailUser && this.isPassword && this.isPassword2)
    },
    password2(newVal) {
      this.isPassword2 = this.password === newVal
      this.isDisabled = !(this.isFirstName && this.isLastName && this.isEmailUser && this.isPassword && this.isPassword2)
    },
  },
}
</script>

<style scoped>
.form-my {
  max-width: 1050px;
  padding: 30px;
  margin: 0 auto;
}
.is-valid-color {
  border: 3px solid #8DCD8D;
}
.is-un-valid-color {
  border: 3px solid #CD5C60;
}
.text-valid {

}
.text-un-valid {
  color: #CD5C60;
}
</style>