

export const HexagramStore = {
    state: () => {
        return {
            hexagramList:[
                {
                    id: 1,
                    name: '1. Цянь. Творчество',
                    binaryCod: '111111',
                    description: 'Символ состоит из шести черт ян. Добрый знак. Это мужская гексаграмма, означающая месяц апрель и весенние надежды. Но будьте осмотрительны! Вы на вершине горы, и возможности спуститься у вас нет. Так что будьте бдительны и рассудительны, пока находитесь наверху. Не позднее, чем через шесть месяцев ждите крупных перемен. Время благоприятствует вашим начинаниям. В личной жизни у вас известная неопределенность, необходимо внести в нее ясность. Если желание ваше разумно и скромно, оно непременно исполнится. Кто-то противостоит вам, но, если вы будете решительны и непреклонны, вас ожидает успех. Испытание вы выдержите.',
                },
                {
                    id: 2,
                    name: '2. Кунь. Исполнение',
                    binaryCod: '000000',
                    description: 'Боги пробудили мать-землю. Трудитесь усердно, и через два месяца усилия ваши увенчаются большим успехом. Вы культурный и жаждущий знаний человек, с любовью относитесь даже к самым малым плодам своего труда. Очень привязаны к матери. Сейчас не время давать волю корыстолюбию, так что не думайте слишком много о материальной выгоде. Желание ваше исполнится, хотя и не сразу. В ближайший период не рекомендуется отправляться в дорогу - ни одному, ни с кем-либо. Скоро в вашем окружении появится человек, питающий к вам сильный интерес.',
                },
                {
                    id: 3,
                    name: '3. Чжунь. Начальная трудность',
                    binaryCod: '010001',
                    description: 'Одна из самых злосчастных комбинаций. За что бы вы не брались, все будет валиться из рук, не давая результата. Не остается ничего другого, как ждать более благоприятного времени. По меньшей мере три месяца старайтесь вести замкнутый образ жизни, что вовсе не означает, что в этот период не следует пытаться продумывать планы на будущее. Будьте терпеливы, прислушайтесь к советам женщины. Об удачливости, везении в делах в данный момент не может быть и речи.',
                },
                {
                    id: 4,
                    name: '4. Мын. Ошибки молодости',
                    binaryCod: '100010',
                    description: 'Все вокруг вы видите, будто сквозь некую пелену, но она скоро спадет и мир вновь обретет для вас ясность. Однако, поскольку в настоящее время ваши нервы сильно расшатаны, не принимайте скоропалительных решений. Если хотите добиться успеха, не пренебрегайте советами начальства, вдумайтесь в них; скоро все изменится. Не унывайте. Побольше времени уделяйте общению с детьми. Новые планы, новые перспективы уже возникают, но даже близко нет новой любви. Сконцентрируйте волю на исполнение одного единственного желания.',
                },
                {
                    id: 5,
                    name: '5. Сюй. Ожидание',
                    binaryCod: '010111',
                    description: 'Ждите и собирайтесь с силами, очень скоро они вам понадобятся - когда наступит весна, сойдет снег и вновь зацветут цветы. Слишком торопливые действия сейчас могут принести только вред. Потерпите еще немного, месяца два, пока не явится некий человек, который поможет вам так, как вы и не ждете. Если же вы теперь решительно и энергично займетесь планированием дальнейших действий, то помощь и поддержка придут еще быстрее. Ваше материальное положение внезапно улучшится. Возможно, вы почувствуете влечение к человеку старше вас. Старайтесь не слишком обнаружить свой интерес к нему. Желание ваше исполнится, если вы будете действовать целеустремленно и в достаточной мере осмотрительно.',
                },
                {
                    id: 6,
                    name: '6. Сун. Конфликт',
                    binaryCod: '111010',
                    description: 'Вы ощущаете дисгармонию. Назревает конфликт. Ведите себя скромно и сдержанно; если вам бросают перчатку, не поднимайте ее. Постарайтесь умерить и свои требования, не пускайтесь ни в какие предприятия в одиночку, пока обстоятельства не изменятся к лучшему. Напротив, примите участие в совместной с кем-либо работе. Пусть все идет своим порядком, тогда и наступит мир и покой, и вы лишний раз убедитесь в том, что жизненные проблемы не только удручают, но и учат.',
                },
                {
                    id: 7,
                    name: '7. Ши. Войско',
                    binaryCod: '000010',
                    description: 'Эта гексаграмма символизирует сознательное уединение. Ваше нынешнее состояние подобно состоянию генерала, решающего, когда лучше всего начать наступление. Будьте внимательны, отбирайте себе в союзники людей с добрыми намерениями, и хотя удача в данный момент сопутствует вам, не забывайте о мерах предосторожности. Вы получите неожиданное известие, или вас посетит нежданный визитер. Настоящее время исполнено для вас романтики, что, впрочем, не помешало возникновению разлада с близким человеком. Все будущие дела планируйте тщательно и рассудительно.',
                },
                {
                    id: 8,
                    name: '8. Би. Сближение',
                    binaryCod: '010000',
                    description: 'Самое худшее уже позади, но кое-какие трудные проблемы еще сохранились. Участвуйте в общих делах, успех ожидает вас в сотрудничестве с другими. Храните верность, не пренебрегайте своими обязанностями. Любящие друг друга точно также нуждаются в взаимном уважении и понимании, как учитель и ученик. Исполнение желаний во многом зависит от того, как вы воспользуетесь советами начальства и друзей. Для азартных игр время неподходящее.',
                },
                {
                    id: 9,
                    name: '9. Сяо-чу. Воспитание малым',
                    binaryCod: '110111',
                    description: 'Везение, успех не слишком характерны для вашего нынешнего положения, но следует иметь в виду, что ночь наиболее темна перед самым рассветом. Обстановка вам не ясна, вы не владеете ею, но это переходное состояние. События, которые изменят его к лучшему, уже на подходе. А пока наберитесь терпения и ждите. Вас привыкли считать человеком, к которому деньги сами текут в руки. Будьте предусмотрительны, не ссорьтесь по мелочам с друзьями и знакомыми, не переусердствуйте в работе, чтобы не изломать себя. Через три месяца деньги действительно потекут к вам.',
                },
                {
                    id: 10,
                    name: '10. Ли. Наступление',
                    binaryCod: '111011',
                    description: 'В настоящее время ваше поведение должно быть подчеркнуто вежливым, дружелюбным и сдержанным. Уйдите в себя и как следует обдумайте свое положение. Каким-либо способом выкажите уважение к начальству, сейчас это пойдет вам на пользу. Произойдет неожиданное событие, которое доставит вам большую радость. Для флирта время неподходящее. Дамы, общаясь с недостаточно хорошо знакомыми мужчинами, должны быть особенно осторожны. Это период, когда претензии к жизни следует снизить до минимума.',
                },
                {
                    id: 11,
                    name: '11. Тай. Рассвет',
                    binaryCod: '000111',
                    description: 'Сейчас, когда счастье благоволит вам и карьера ваша продвигается вперед и вверх, именно сейчас подумайте о друзьях. В то же время не будьте легкомысленны и небрежны. Если хотите, чтобы везение и успех сопутствовали вам как можно дольше, постарайтесь подкрепить их успехами и в других областях. Желание ваше исполнится, но не стремитесь искусственно ускорить его осуществление. В настоящий момент вы склонны сорить деньгами; сдерживайте себя, держитесь в рамках своих финансовых возможностей.',
                },
                {
                    id: 12,
                    name: '12. Пи. Упадок',
                    binaryCod: '111000',
                    description: 'Вам многое неясно, волнуют проблемы общественной жизни. К вам тянутся люди, недостойные вас. Будьте бдительны и предусмотрительны, не принимайтесь ни за что существенное. Ваше окружение не понимает вас: вы без достаточно на то оснований ссоритесь с одним из друзей. Желания ваши исполнятся процентов на восемьдесят, но и то не сразу. В эти дни следите за своим кошельком. Прислушивайтесь к советам начальства, но решения принимайте по собственному усмотрению. Положение скоро изменится.',
                },
                {
                    id: 13,
                    name: '13. Тун жэнь. Единомышленники',
                    binaryCod: '111101',
                    description: 'Куда бы вы не отправились , попадете в хорошее общество. Можете смело вступать в борьбу, преследуя высокие цели: вас поддержит человек, на которого вы не рассчитывали. Очень скоро дела ваши пойдут на лад. Вы несколько неуверенны и беспокойны, это следствие легкого стресса, в котором вы пребываете. Тем не менее желание ваше исполнится, причем настолько полно, насколько серьезно вы отнесетесь к предлагаемой вам помощи старших.',
                },
                {
                    id: 14,
                    name: '14. Дао ю. Обладание великим',
                    binaryCod: '101111',
                    description: 'Солнце светит ярко. Это означает, вы ощущаете себя сейчас особенно свободным; кроме того, вы вполне богаты - как с материальной, так и с духовной точки зрения. Сконцентрируйте волю на исполнении тех своих желаний, осуществление которых необходимо для успеха в делах. Хотя данный период благоприятен для вас, старайтесь не перегружать себя, постоянно держите в поле зрения главную цель. Не сорите деньгами!',
                },
                {
                    id: 15,
                    name: '15. Цянь. Смирение',
                    binaryCod: '000100',
                    description: 'До самой земли склонилась ветвь дерева под тяжестью снега, но скоро она вновь выпрямится и займет прежнее положение. Обстоятельства ваши в данный момент можно назвать лишь умеренно благоприятными. Если поведение ваше будет отличаться сейчас умеренностью и сдержанностью, впоследствии вы станете хозяином положения. В настоящее время по ряду причин, в том числе финансового порядка, вы испытываете некоторое замешательство и беспокойство, но не тревожьтесь, скоро все изменится к лучшему. Если не отвергнете помощь других, желание ваше исполнится. В дальнейшем финансовые дела поправятся.',
                },
                {
                    id: 16,
                    name: '16. Юй. Вольность',
                    binaryCod: '001000',
                    description: 'Ваше солнце вот-вот взойдет, удача на подходе, вы ждали ее достаточно долго. Время благоприятствует всякого рода развлечениям, главное - не увлекаться ими сверх меры. Будьте готовы к успеху, который скоро последует. Счастье будет сопутствовать вам в осуществлении новых замыслов, а также в игре. Шагая по пути успеха, не забывайте смотреть под ноги, чтобы не погрязнуть в мелочности и меркантильности. Желания непременно исполнятся, но нужно подождать еще немного. Будьте осторожны, не тратьте больше, чем получаете.',
                },
                {
                    id: 17,
                    name: '17. Суй. Следование',
                    binaryCod: '011001',
                    description: 'Время более подходит для упрочения руководящих позиций, нежели для вербовки сторонников. Будьте верны своим принципам даже в том случае, если цели ваши изменятся. Впредь довольствуйтесь пока малыми победами и успехами, они непременно повлекут за собой и более крупные. Плывите по течению, а не против него, и тогда все придет в порядок. Желание ваше исполнится с большой долей вероятности, но будьте готовы к серьезным переменам в жизни.',
                },
                {
                    id: 18,
                    name: '18. Гу. Исправление испорченного',
                    binaryCod: '100110',
                    description: 'Вы станете участником ряда перемен и преобразований в личной жизни других. Тщательно анализируйте, оценивайте события. Вам следует быть хозяином положения, что постепенно станет возможным само собой, благодаря вашему нынешнему состоянию. Вы можете потерять друга. Скоро вы совершите нечто такое, что ошеломит ваших знакомых, а быть может, и вас самих. Отношения с окружающими у вас не в порядке, возникающие отсюда проблемы способны помешать исполнению ваших желаний, так что отношения необходимо прояснить. Будьте предусмотрительны! Тратите вы слишком много.',
                },
                {
                    id: 19,
                    name: '19. Линь. Приближение',
                    binaryCod: '000011',
                    description: 'За что бы вы ни взялись сейчас, вас ждет успех. И чем больших результатов вы добьетесь, тем удачнее будут складываться обстоятельства. Близится период, во время которого возможны крупные успехи сразу во многих делах и сферах деятельности. Решительно добивайтесь намеченных целей, но при этом будьте осмотрительны, чтобы не обидеть, не рассердить и не вызвать зависть друзей. Один из близких вам людей, которого вы очень хорошо знаете, вынудит вас изменить к нему отношение.',
                },
                {
                    id: 20,
                    name: '20. Гуань. Созерцание',
                    binaryCod: '110000',
                    description: 'Будьте готовы к неприятному сюрпризу. Постарайтесь рассудительно и детально осмыслить положение дел; увидеть их так, как они обстоят в действительности. Возможно, вам придется сменить место работы и жительства. Сейчас вам необходимо быть особенно внимательным, не позволяйте важным вещам ускользать за пределы вашего влияния. Тщательно продумывайте все действия и тогда получите помощь оттуда, откуда меньше всего ждете. Желания исполнятся, но не так быстро, как бы вам хотелось. Хорошенько обдумайте и проанализируйте возможности осуществления ваших планов, а если дела пойдут успешно, не забывайте помогать другим.',
                },
                {
                    id: 21,
                    name: '21. Ши-хо. Стиснутые зубы',
                    binaryCod: '101001',
                    description: 'Что-то мучает вас, вы чувствуете себя несчастным. Попробуйте взяться за новое дело, и обстоятельства ваши улучшатся. Добросовестная работа постепенно и неуклонно приведет вас к очень крупному успеху. Вы считаете себя жертвой несправедливости. Если будете постоянно думать о том, как такое могло случиться, делу это не поможет. Очевидно, в чем-то вы допустили ошибку, одну из тех, какие в огромном множестве допускают и другие. Наберитесь мужества и извлеките из случившегося должный урок. Что касается исполнения вашего желания, то как раз сейчас кое-кто содействует этому. Оставайтесь рассудительным и ждите спокойно, тогда очень скоро все переменится к лучшему для вас.',
                },
                {
                    id: 22,
                    name: '22. Би. Украшение',
                    binaryCod: '100101',
                    description: 'Данная гексаграмма благоприятна только для дел, имеющих отношение к театру. Она означает, что вы скрываете свое подлинное лицо и что окружающие вас люди тоже ведут себя не вполне искренне и честно. Любовные дела в данный момент довольно проблематичны. Возможны определенные успехи на поприще шоу-бизнеса. Желание исполнится после некоторой задержки. Примите жизнь такой, какая она есть и извлекайте уроки.',
                },
                {
                    id: 23,
                    name: '23. Бо. Разрушение',
                    binaryCod: '100000',
                    description: 'В ближайшем будущем вас ожидают крупные перемены. Сейчас не принимайтесь ни за какое новое дело, вам нельзя попадать в глупое положение. Возможно, кто-то злословит по вашему адресу, распускает сплетни. Желания ваши исполнятся чуть позже. Не робейте, если в какой-то момент окажется, что нельзя рассчитывать на помощь друзей. Будьте осмотрительны в общении с лицемерными представителями другого пола.',
                },
                {
                    id: 24,
                    name: '24. Фу. Возвращение',
                    binaryCod: '000001',
                    description: 'Вам кажется, что вы совершенно запутались в окружающей обстановке и вы сильно нервничаете по этому поводу, однако помехи малосущественны, и вы справитесь с ними очень скоро, если будете усердно и добросовестно работать. Некий вполне определенный срок - семь дней или семь недель, а быть может, семь месяцев - обретет для вас большое значение. По всей вероятности, вы сами чувствуете, что стоите на пороге серьезных перемен в судьбе, и это действительно так. Еще немного, и все устроится. Постепенно, монетка к монетке, скапливайте деньги.',
                },
                {
                    id: 25,
                    name: '25. У-ван. Беспорочность',
                    binaryCod: '111001',
                    description: 'Господствует единство ясности и простоты, которое принесет вам пользу, если достойные замыслы вы будете проводить в жизнь достойными средствами. Время высшей активности еще не настало. Судьба скоро улыбнется вам, но лишь в том случае, если вы умеете терпеливо ждать. Порой вы слишком озабочены любовными делами, но не волнуйтесь, все ваши желания исполнятся в свой срок.',
                },
                {
                    id: 26,
                    name: '26. Да-чу. Воспитание великим',
                    binaryCod: '100111',
                    description: 'Не тратьте понапрасну силы, в вашей судьбе наступила пауза. Ждите ее окончания в полной готовности, не разменивайте энергию по мелочам, скоро ей найдется более приятное и полезное применение. Желания исполнятся, но лишь в том случае, если планку вы установили не слишком высоко. Помощь придет от тех, кто столкнулся с проблемами, подобными вашим. Не пытайтесь силой ускорить ход вещей, результат может оказаться обратным.',
                },
                {
                    id: 27,
                    name: '27. И. Питание',
                    binaryCod: '100001',
                    description: 'Вы слишком много говорите и слишком много едите. Не сплетничайте о других, так вы навредите не только им, но и себе. Желание исполнится, но несколько позже, чем вам хотелось бы. Прекратите сетовать на судьбу. Вам было бы нелишне показаться врачу. Будьте готовы к скорым переменам в жизни.',
                },
                {
                    id: 28,
                    name: '28. Да-го. Превосходство великого',
                    binaryCod: '011110',
                    description: 'Вы ощущаете себя очень счастливым. Желательно взять себя в руки, поскольку ваш темперамент, если дать ему волю, может повредить как другим, так и вам самому. Не будьте излишне самоуверенны, ваши суждения в настоящее время отнюдь не самые верные не пытайтесь добиться успеха силой. Сдержите себя и поразмыслите над состоянием дел. Время все изменит. Желание ваше не может исполниться быстро. Остерегайтесь обидеть своей горячностью других.',
                },
                {
                    id: 29,
                    name: '29. Си-кань. Повторная опасность',
                    binaryCod: '010010',
                    description: 'Одна из четырех наихудших комбинаций. В настоящее время для вашей жизни характерны потери и поражения. Единственное, что вы можете сделать, это сократить до возможного минимума число ударов судьбы. Наберитесь терпения и ждите, пока богиня счастья вновь удостоит вас взгляда. Времени у вас много, займитесь научными изысканиями. Через два, самое большее - пять месяцев положение начнет изменяться к лучшему. До тех пор храните спокойствие и не падайте духом. Этот период, когда самоанализ и трезвая оценка положения гораздо важнее, чем отчаянная борьба с судьбой.',
                },
                {
                    id: 30,
                    name: '30. Ли. Сияние',
                    binaryCod: '101101',
                    description: 'Внешне все обстоит превосходно, но в действительности это не так. Вы в плену иллюзий, если не сказать - сознательного самообмана. Прислушайтесь к советам друга. Судьба в данный момент благоволит вам, но это обманчивое впечатление, оно может ввести в заблуждение и нанести серьезный ущерб, если вы целиком положитесь на фортуну. Желание ваше исполнится благодаря вмешательству человека старше вас. В делах, связанных с сочинительством и посредничеством, возможны крупные успехи. Прислушайтесь к тому, что говорят люди.',
                },
                {
                    id: 31,
                    name: '31. Сянь. Взаимодействие',
                    binaryCod: '011100',
                    description: 'Душевному состоянию, в котором вы пребываете, обычно сопутствуют везение и успех. Вы настроены на хорошую волну. Близятся несколько неожиданных и очень выгодных для вас событий, почти каждый ваш шаг принесет удачу. Реальные результаты превысят даже самые смелые ожидания, что внесет в вашу душу небывалый покой. Единственный негативный фактор: после всех волнений возникает необходимость ненадолго уйти от дел и отдохнуть.',
                },
                {
                    id: 32,
                    name: '32. Хэн. Постоянство',
                    binaryCod: '001110',
                    description: 'Вы разрываетесь на части, пытаясь двигаться сразу в двух направлениях. Если сохраните выдержку, все завершится с пользой для вас. Не стремитесь к переменам. Желание ваше исполнится, если вы будете терпеливы. Нелишне сейчас провести внутреннюю "инвентаризацию" и попытаться как следует разобраться в дальнейших планах, намерениях. Для новых начинаний момент неподходящий.',
                },
                {
                    id: 33,
                    name: '33. Дунь. Бегство',
                    binaryCod: '111100',
                    description: 'Вы только выиграете, если немного сдержите себя. Напористость в данный момент пользы не принесет. Эта гексаграмма благоприятна для интересного отдыха и развлечений, а также для консолидации планов на будущее. Осуществление планов отложите до окончания нынешнего периода неопределенности. Используйте его для медитации.',
                },
                {
                    id: 34,
                    name: '34. Да-чжуань. Мощь великого',
                    binaryCod: '001111',
                    description: 'Вы стремитесь на передний план слишком активно, более, чем это выгодно для вас в данное время. Вы готовы растоптать окружающих, что, мягко говоря, не доставило бы им удовольствия. В честолюбии нет ничего плохого, но оно непременно должно сопровождаться тактичностью в обращении с людьми. Желание ваше исполнится, если вы, будете вести себя несколько скромнее. Постарайтесь отыскать в этом отношении "золотую середину".',
                },
                {
                    id: 35,
                    name: '35. Цзинь. Восход',
                    binaryCod: '101000',
                    description: 'Удача на подходе. Вас ждут уважение и признание, на которые вы по праву рассчитывали. В будущем вознаграждение будет еще выше. Положитесь на свою счастливую звезду и смело шагайте вперед, при этом пусть вас не печалит, что желание ваше исполнится лишь с некоторой задержкой. Вы неожиданно встретитесь с человеком, которого не видели очень давно. Постарайтесь тратить немного, в дальнейшем такая экономность воздается вам сторицей.',
                },
                {
                    id: 36,
                    name: '36. Мин-и. Затмение света',
                    binaryCod: '000101',
                    description: 'В настоящее время вам сопутствует удача, но не будьте слишком самонадеянны, ситуация скоро изменится. Действуйте обдуманно и предусмотрительно, не увлекайтесь любовными авантюрами. Со стороны вы производите впечатление баловня судьбы, и поэтому вполне возможно, что окружающие истолковывают ваши поступки превратно, но не тревожьтесь, в ближайшем будущем все станет на свои места. Желания ваши сейчас не исполнятся. Будьте экономны.',
                },
                {
                    id: 37,
                    name: '37. Цзя-жэнь. Семья',
                    binaryCod: '110101',
                    description: 'Ваше место там, куда тянет сердце, там же вас ждет и благополучие. Надежды исполнятся с посторонней помощью. Возможно, про себя вы думаете, что где-то трава зеленее, но поверьте, если покинете сейчас свой край, очень скоро убедитесь, что совершили ошибку. Наслаждайтесь семейной жизнью, общением с друзьями, покоем в доме.',
                },
                {
                    id: 38,
                    name: '38. Куй. Разлад',
                    binaryCod: '101011',
                    description: 'Эта гексаграмма означает, что данный период вашей жизни лишен гармонии. Вам кажется, будто все вокруг придираются к вам, настроены злонамеренно. Даже самые незначительные мелочи выводят вас из себя. Успокойтесь, не нервничайте, положение скоро исправится. Сподвижников вам сейчас найти трудно. Некая женщина действует вам на нервы. Следите за своими словами и не принимайтесь ни за что новое. В поле ваших интересов в данный момент находится целый ряд вещей, совершенно несоответствующих вашим истинным желаниям.',
                },
                {
                    id: 39,
                    name: '39. Цзянь. Препятствие',
                    binaryCod: '010100',
                    description: 'Одна из самых худших гексаграмм. Чем больше усилий вы прилагаете, тем дальше оказываетесь оттесненными назад. Трудно сказать, почему именно, но в данный момент Фортуну ваша судьба не интересует. Лучше всего погрузиться в учебу или научную деятельность и в них найти успокоение. Вполне возможно, что несколько ваших друзей придут вам на помощь, окажут поддержку. Следите за своими расходами: в этот недобрый период реальна опасность остаться без средств.',
                },
                {
                    id: 40,
                    name: '40. Цзе. Освобождение',
                    binaryCod: '001010',
                    description: 'Вы пережили долгий период беспокойства и неприятностей. Этому периоду настал конец. В работе снова будет успех. Немедленно начинайте действовать, иначе упустите возможность добиться великолепного результата. Некое старое ваше желание исполнится, новое - тоже, но чуть позднее. У вас появятся новые друзья. Если отправитесь в путешествие, оно доставит вам огромное удовольствие. Начавшийся период благоприятен для того, чтобы хорошо заработать.',
                },
                {
                    id: 41,
                    name: '41. Сунь. Уменьшение',
                    binaryCod: '100011',
                    description: 'То, что вы сегодня отдаете другим, завтра судьба возвратит вам с процентами. Быть может, вам сейчас кажется, будто вы слишком расточительны, слишком одариваете других, но это впечатление скоро пройдет и вы будете щедро вознаграждены. Время благоприятствует обдумыванию планов и интеллектуальной деятельности. Большинство ваших желаний исполнится, причем пожнете вы даже больше, чем сеяли.',
                },
                {
                    id: 42,
                    name: '42. И. Увеличение',
                    binaryCod: '110001',
                    description: 'Время благоприятствует выдающимся личностям, но и для прочих оно плодотворно. Осуществится задуманное, будет оплачено сделанное. Вы сможете оказать помощь другим. Некое дело, которое вам поручит начальство, обернется личной выгодой для вас. Кто-то готовится сделать вам выгодное предложение. Желание исполнится с помощью вышестоящего лица. В ближайшем будущем - возможность неожиданно, но существенно поправить финансовые дела.',
                },
                {
                    id: 43,
                    name: '43. Гуай. Прорыв',
                    binaryCod: '011111',
                    description: 'В настоящий период вы удачливы, но легко может случиться, что из-за собственного упрямства совершите ошибку и оттолкнете от себя тех, кто обычно помогал вам. Пойдите сами им навстречу и проявляйте больше терпимости в отношениях с ними. Вполне возможно, что в этот период вы полюбите человека, которому мало симпатизируете, и это, разумеется, окажет влияние на ваше поведение. Не играйте в азартные игры.',
                },
                {
                    id: 44,
                    name: '44. Гоу. Приход на встречу',
                    binaryCod: '111110',
                    description: 'Хорошо, если характерной чертой вашего нынешнего поведения будет сдержанность. Отнеситесь внимательно к переменам в контактах с людьми и попытайтесь оценивать их действия менее критично. Исполнение желаний и надежд проблематично. Будьте экономны. Внутренне подготовьтесь к тому, что скоро последуют неожиданные события, не сулящие вам ничего благоприятного.',
                },
                {
                    id: 45,
                    name: '45. Цуй. Воссоединение',
                    binaryCod: '011000',
                    description: 'Все, что вы сделаете в этот период, за что приметесь, завершится успешным результатом. Некая невидимая сила упрочит ваши отношения с людьми, поможет завязать новые тесные контакты, которые так или иначе станут для вас благоприятными. Былые труды и усилия будут оплачены. Вас преследует женщина, она стремится преградить вам путь, помешать осуществлению ваших намерений, вмешаться в вашу жизнь. Несмотря на это, желания ваши исполнятся.',
                },
                {
                    id: 46,
                    name: '46. Шэн. Подъем',
                    binaryCod: '000110',
                    description: 'То, над чем вы трудитесь уже долгое время, наконец даст благоприятный результат и будет оплачено. Надо только еще немного поработать, настойчиво и добросовестно, тогда вскоре можно рассчитывать на высокую оценку и признание. Смелые и решительные действия сейчас гораздо более благоприятны, чем бездеятельное выжидание. Положитесь на свой здравый смысл и интуицию, и тогда желание ваше наверняка исполнится. Идеи, которые вы в данный момент обдумываете, скорее, всего принесут успех и в финансовом смысле.',
                },
                {
                    id: 47,
                    name: '47. Кунь. Истощение',
                    binaryCod: '011010',
                    description: 'Одна из четырех наихудших гексаграмм. Это не тот период, когда стоило бы приниматься за что-либо. Затаитесь и ждите. Мысли ваши недостаточно ясны. Некто из вашего окружения, занимающий высокое положение, протянет вам руку помощи. Прислушайтесь к советам этого человека. Вера в собственные силы у вас сейчас очень слабая. Но не принимайте события слишком трагично, ведь и этот негативный период пройдет и обстоятельства ваши улучшатся.',
                },
                {
                    id: 48,
                    name: '48. Цзин. Колодец',
                    binaryCod: '010110',
                    description: 'Хорошо, если вы протянете другим руку помощи, даже если у вас самого дела идут не вполне гладко. Впоследствии вы убедитесь, что такой образ действий очень выгоден. В данный период едва ли можно рассчитывать на признание ваших способностей и достижений начальством, что, конечно, малоприятно. Вы страстно желаете, чтобы обстоятельства изменились, но время сейчас не благоприятствует этому. Скромные ваши желания могут исполнится и теперь, а вот крупные - нет. Как ни странно, нынешнее ваше финансовое положение не так плохо.',
                },
                {
                    id: 49,
                    name: '49. Гэ. Смена',
                    binaryCod: '011101',
                    description: 'Все вокруг вас пребывает сейчас в движении, все меняется, но в конце добрые результаты превзойдут даже самые смелые ваши ожидания. В настоящий момент вы не чувствуете в себе уверенности, но скоро она вновь вернется к вам вместе с новыми перспективами, обстоятельства изменятся к лучшему. Вы измените свои недавние планы и отправитесь туда, куда прежде и не собирались. В игре вы сейчас удачливы.',
                },
                {
                    id: 50,
                    name: '50. Дин. Жертвенник',
                    binaryCod: '101110',
                    description: 'Эта гексаграмма имеет для вас позитивное значение, если возьмете на себя роль лидера. Кое-кого ваши успехи не радуют, но не обращайте на этих людей внимания. Остерегайтесь брать на себя или обещать больше, чем в состоянии выполнить. Сильное влияние на вас и на ваши отношения с людьми имеет цифра 3. Дело, за которое вы принялись вместе с двумя единомышленниками, приведет к успеху. Желание исполнится, хоть и не совсем так, как вы изначально задумывали. Вы слишком много тратите на развлечения и на хобби.',
                },
                {
                    id: 51,
                    name: '51. Чжэнь. Пробуждение, гром',
                    binaryCod: '001001',
                    description: 'Вы близки к успеху, но кто-то прилагает силы, чтобы помешать вам его достигнуть. Не расстраивайтесь из-за этого. То, что вы стремились получить как необходимое вам, на деле окажется совершенно другим, не таким, каким представлялось, так что смело и со спокойной душой можете уступить это сопернику. Скоро в вашем окружении произойдет нечто неожиданное и неприятное, впрочем, это событие ничем не повредит вам. Период благоприятен для отдыха. Не нервничайте из-за того, что обстоятельства складываются именно так, а не иначе. Чуть позднее и вам улыбнется судьба.',
                },
                {
                    id: 52,
                    name: '52. Гэнь. Сосредоточенность',
                    binaryCod: '100100',
                    description: 'В настоящее время прогресс в делах проблематичен, очевидно, следует переждать немного и лишь затем снова приступить к работе. От запланированной поездки или путешествия лучше отказаться. Примиритесь с обстоятельствами и тщательно обдумайте свое положение, прежде чем браться за что-либо. Выше голову! Победа над трудностями и кознями недоброжелателей будет за вами, причем очень скоро. Об исполнении желаний без настойчивых усилий с вашей стороны не может быть и речи.',
                },
                {
                    id: 53,
                    name: '53. Цзянь. Развитие',
                    binaryCod: '110100',
                    description: 'Если вы будете продвигаться вперед, тщательно продумывая каждый шаг, успех и удача не изменят вам и в дальнейшем. Счастье и впредь останется вашим спутником, если, не поддаваясь на уговоры, вы не будете опережать события. У черепахи не меньше шансов прийти к финишу первой, чем у зайца. Вы в начале долгого пути. Желание в конце концов исполнится. Финансовые дела существенно поправятся.',
                },
                {
                    id: 54,
                    name: '54. Гуй-мэй. Невеста',
                    binaryCod: '001011',
                    description: 'Всегда и во всем будьте осторожны, особенно в любовных делах. Происходящее воспринимайте спокойно и хладнокровно, иначе можете попасть в очень неприятное положение. Исполнение желания затягивается. Это период, когда лучше ждать и думать. Одновременно это период, когда доходы превышают расходы. Ближайшие недели очень благоприятны для подготовки к следующему, более благоприятному периоду.',
                },
                {
                    id: 55,
                    name: '55. Фын. Изобилие',
                    binaryCod: '001101',
                    description: 'Эта гексаграмма символизирует осеннюю жатву, сбор богатого урожая и означает, что ваша счастливая звезда ярко освещает все ваши дела. Они идут успешно и ровно, а в будущем, возможно, пойдут еще успешнее и ровнее. Не забывайте о том, что для вас очень важно всегда быть хорошо информированным и что определенную часть заработанных денег следует откладывать. Данный период особенно благоприятен для дел, связанных с сельским хозяйством, искусством и шоу-бизнесом. Однако, хотя сейчас вам сопутствует успех, нет полной уверенности, что желания ваши исполнятся.',
                },
                {
                    id: 56,
                    name: '56. Люй. Странствие',
                    binaryCod: '101100',
                    description: 'Есть все предпосылки для успеха в делах. Успешно пройдет и заграничная научная поездка, если таковая планируется. Вы очень честолюбивы, будьте осмотрительны, чтобы не испортить отношения с друзьями и коллегами по работе. Желание исполнится, если претензии ваши не слишком высоки и вы не ждете слишком многого. Вы очень нервничаете по поводу некоего неприятного события. Это не разумно, забудьте и не вспоминайте!',
                },
                {
                    id: 57,
                    name: '57. Сунь. Проникновение',
                    binaryCod: '110110',
                    description: 'Положение ваше запутанно, и в данный момент не так просто в нем разобраться и оценить его. Вы склонны изображать события слишком мрачно. Следуйте по пути, который укажет вам человек из вашего окружения, и убедитесь, что это наилучший выход. Через 5 месяцев ваша судьба изменится к лучшему. Что-то препятствует исполнению ваших желаний, устранить помехи вам поможет женщина. В данный период ни в коем случае не позволяйте уговорить себя на такие действия, которые считаете неуместными и ошибочными.',
                },
                {
                    id: 58,
                    name: '58. Дуй. Радость',
                    binaryCod: '011011',
                    description: 'Счастье уже на пути к вам, оно развеет нынешнее подавленное настроение. Эта гексаграмма имеет отношение ко всему, что связано с органами речи. Не пренебрегайте добрым советом друга и остерегайтесь дурно отзываться об окружающих. Очень благоприятный период для всего, что касается торговли. Не нервничайте по поводу того, что вы все равно изменить не в силах. Сохраняйте спокойствие; счастье, удача уже на подходе.',
                },
                {
                    id: 59,
                    name: '59. Хуань. Раздробление',
                    binaryCod: '110010',
                    description: 'После долгого периода неудач вновь восходит солнце успеха. Оно принесет вам все, к чему вы стремитесь. Возможно вас ожидает длительное путешествие. Не тратьте много денег. Что касается ваших научных, производственных и любовных дел, то они пойдут блистательно. В ближайшее время у вас появится неожиданный шанс занять лидирующее положение. Желание ваше уже исполняется и исполнится целиком, если вы по-прежнему будете прилагать для этого настойчивые и целенаправленные усилия.',
                },
                {
                    id: 60,
                    name: '60. Цзе. Ограничение',
                    binaryCod: '010011',
                    description: 'Ваше время пришло, активно принимайтесь за дело, но, разумеется, помня о необходимой предусмотрительности, иначе все усилия пойдут прахом. Не принимайте слишком поспешно то предложение, которое вам только что сделали или вот-вот сделают. Вполне возможно, что оно гораздо менее выгодно вам, чем на первый взгляд кажется. То же самое относится к вашим новым любовным и дружеским связям. Разумные и реальные желания исполнятся. Для путешествий, дальних поездок время неподходящее. Помните пословицу: не рой другому яму, сам в нее попадешь.',
                },
                {
                    id: 61,
                    name: '61. Чжун-фу. Тайная истина',
                    binaryCod: '110011',
                    description: 'Работать не одному, а в сотрудничестве с другими для вас сейчас наиболее выгодно. Остерегайтесь переоценивать свои умственные способности, чтобы не стать спесивым и невыдержанным. Это может поставить под угрозу осуществление ваших планов. Постарайтесь быть скромнее и сдержаннее. Лишь в этом случае, если желания ваши разумны и честны, они исполнятся. Начальство в ближайшем будущем обратит внимание на ваши способности и возможности, оценит их по достоинству, что, естественно, в значительной мере поможет вашему движению по пути успеха.',
                },
                {
                    id: 62,
                    name: '62. Сяо-го. Превосходство малого',
                    binaryCod: '001100',
                    description: 'Возможно, вы разочаруетесь в том, кого любите, но не воспринимайте все слишком трагично. Наилучший шанс, который вернее любого другого способствовал исполнению вашего желания, вы упустили. Но скоро появится новый. Данный период отмечен возможностями хороших заработков, однако для дальних поездок подходит мало. В настоящее время вы придаете слишком много значения мелочам и тем самым размениваете, унижаете себя.',
                },
                {
                    id: 63,
                    name: '63. Цзи-цзи. Законченность',
                    binaryCod: '010101',
                    description: 'Если вы поссоритесь с кем-либо, срочно обратитесь к третьему лицу с просьбой выступить посредником между вами. Не поддавайтесь желанию вновь повторить однажды достигнутый успех. Если как следует обдумаете этот совет и последуете ему, наградой вам будет полное осуществление ваших желаний. В данный момент не бросайте все силы на новое назначение, оно не приведет к успеху. Загаданное конкретное желание исполнится с некоторой задержкой.',
                },
                {
                    id: 64,
                    name: '64. Вэй-цзи. Незаконченность',
                    binaryCod: '101010',
                    description: 'Удача, которая отметит все ваши дела, стоит на пороге. Но действовать еще пока рано. Если будете продвигаться вперед достаточно осмотрительно, обстоятельства будут складываться лучше день ото дня. Желание исполнится в ближайшее время. На пороге счастливый период вашей жизни, ждать которого осталось недолго.',
                },
            ],
            questionStore: [
                // {
                //     id: '1',
                //     question: 'что будет завтра',
                //     binCod: '000000',
                //     theTime: '11.23 01.02.22',
                // },
                // {
                //     id: '2',
                //     question: 'что будет на неделе',
                //     binCod: '111111',
                //     theTime: '12.23 01.02.22',
                // },
            ],
            selectHexaStore: [
                // {
                //     id: '1',
                //     binCod: '000000',
                //     theTime: '01.03.2022 13.23.12',
                // },
                // {
                //     id: '2',
                //     binCod: '010001',
                //     theTime: '01.03.2022 13.34.12',
                // },
                // {
                //     id: '3',
                //     binCod: '010111',
                //     theTime: '03.01.2022 14.34.12',
                // },
            ],
            userName: 'userHexagramStore'
        }
    },
    getters: {
        hexagramImages(state) {
            let images = {}
            let str = []
            state.hexagramList.forEach( el => {
                let bin = el.binaryCod.split('')
                for (let i = 0; i < bin.length; i++) {
                    if (bin[i] === '0') {
                        str.push(`
                            <div class="d-flex justify-content-between p-1">
                                <div class="border border-dark rounded-1 bg-primary p-1" style="width: 40px; height: 10px"></div>
                                <div class="border border-dark rounded-1 bg-primary p-1" style="width: 40px; height: 10px"></div>
                            </div>
                        `)
                    } else {
                        str.push(`
                            <div class="d-flex p-1">
                                <div class="border border-dark rounded-1 bg-primary p-1" style="width: 95px; height: 10px"></div>
                            </div>
                        `)
                    }
                }
                images[el.binaryCod] = str.join('')
                str = []
            })
            return images
        },
        hexagramBin(state) {
            let obj = {}
            state.hexagramList.forEach( el => {
                obj[el.binaryCod] = {
                    name: el.name,
                    description: el.description,
                }
            })
            return obj
        },
    },
    mutations: {
        initialState(state) {
            const storeObj = localStorage.getItem(state.userName)
            if (storeObj) {
                state.questionStore = JSON.parse(storeObj).questionStoreUser
                state.selectHexaStore = JSON.parse(storeObj).selectStoreUser
            } else {
                state.questionStore = []
                state.selectHexaStore = []
            }
        },
        addQuestion(state, obj) {
            const qs = {
                id: Date.now().toString(),
                question: obj.question1.toString(),
                binCod: obj.binCod1,
                theTime: obj.timeCog,
            }
            state.questionStore.push(qs)
            const storeObj = localStorage.getItem(state.userName)
            if (storeObj) {
                let obj = JSON.parse(storeObj)
                obj.questionStoreUser.push(qs)
                localStorage.setItem(state.userName, JSON.stringify(obj))
            } else {
                localStorage.setItem(state.userName, JSON.stringify(
                    {
                        questionStoreUser: [qs],
                        selectStoreUser: [],
                    }
                ))
            }
        },
        addSelectHexa(state, obj) {
            const sH = {
                id: Date.now().toString(),
                binCod: obj.binCod1,
                theTime: obj.timeCog
            }
            state.selectHexaStore.push(sH)
            const storeObj = localStorage.getItem(state.userName)
            if (storeObj) {
                let obj = JSON.parse(storeObj)
                obj.selectStoreUser.push(sH)
                console.log(obj)
                localStorage.setItem(state.userName, JSON.stringify(obj))
            } else {
                localStorage.setItem(state.userName, JSON.stringify(
                    {
                        questionStoreUser: [],
                        selectStoreUser: [sH],
                    }
                ))
            }
        },
    },
    actions: {},
    namespaced: true,
}
