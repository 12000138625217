<template>
  <nav class="navbar navbar-expand-lg bg-light position-fixed w-100 shadow-sm" style="z-index: 6">
    <div class="container-fluid">
      <RouterLink :to="{ name: 'HomeMy' }" @click="removeNav" class="navbar-brand">Главная</RouterLink>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link  :to="{ name: 'MyKnowledge' }" class="nav-link" aria-current="page" @click="removeNav">Мои знания</router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click="removeNav" href="https://github.com/den089120">Мой github</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Мои работы
            </a>
            <ul class="dropdown-menu">
              <li><router-link :to="{ name: 'CreateHexagram' }" class="dropdown-item" @click="changeShowhexaNav('ShowHexaNavigation')">Книга перемен</router-link></li>
              <li><router-link :to="{ name: 'T_M_S_List' }" class="dropdown-item" @click="changeShowhexaNav('ShowKeeperNav')">программа - Склад</router-link></li>
            </ul>
          </li>
        </ul>

        <ul v-if="isShow.ShowKeeperNav" class="navbar-nav mx-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link
                :to="{ name: 'T_M_S_List' }"
                :class="{ 'active': activeList.isActive }"
                class="nav-link"
                @click="changeActive('isActive')"
            >
              Список ТМЦ
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
                :to="{ name: 'MovementList' }"
                :class="{ 'active': activeList.isActive_1 }"
                class="nav-link"
                @click="changeActive('isActive_1')"
            >
              Список перемещений ТМЦ
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
                :to="{ name: 'T_M_S_Add' }"
                :class="{ 'active': activeList.isActive_2 }"
                class="nav-link" @click="changeActive('isActive_2')"
            >
              Добавить ТМЦ
            </router-link>
          </li>
        </ul>

        <ul v-if="isShow.ShowHexaNavigation" class="navbar-nav mx-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link
                :to="{ name: 'CreateHexagram' }"
                :class="{ 'active': activeList.isActiveHex }"
                class="nav-link"
                @click="changeActive('isActiveHex')"
            >
              Генерация гексаграммы
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
                :to="{ name: 'SelectHexagram' }"
                :class="{ 'active': activeList.isActiveHex_1 }"
                class="nav-link"
                @click="changeActive('isActiveHex_1')"
            >
              Выбрать из таблицы
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
                :to="{ name: 'QuestionHexagram' }"
                :class="{ 'active': activeList.isActiveHex_2 }"
                class="nav-link"
                @click="changeActive('isActiveHex_2')"
            >
              Задать вопрос
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
                :to="{ name: 'HistoryPrediction' }"
                :class="{ 'active': activeList.isActiveHex_3 }"
                class="nav-link"
                @click="changeActive('isActiveHex_3')"
            >
              История предсказаний
            </router-link>
          </li>
        </ul>

      </div>
    </div>
  </nav>
</template>

<script>
import {mapMutations, mapState} from 'vuex';
export default {
  name: "NavBar",
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      isShow: state => state.General.isShow,
      activeList: state => state.General.activeList,
    }),
  },
  methods: {
    ...mapMutations({
      changeShowhexaNav: 'General/changeShowhexaNav',
      removeNav: 'General/removeNav',
      changeActive: "General/changeActive",
    }),
  },
}
</script>

<style>

</style>