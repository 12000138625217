import {createStore} from "vuex";
import {HexagramStore} from "@/store/HexagramStore";
import {MyKnowledgeStore} from "@/store/MyKnowledgeStore";
import {GeneralStore} from "@/store/GeneralStore";
import {KeeperStore} from "@/store/KeeperStore";


export default createStore({
    modules: {
        hexagram: HexagramStore,
        myKnowledge: MyKnowledgeStore,
        General: GeneralStore,
        Keeper: KeeperStore,
    }
})