
export const GeneralStore = {
    state: () => {
        return {
            isShow: {
                ShowHexaNavigation: false,
                ShowKeeperNav: false,
            },
            activeList: {
                isActive: true,
                isActive_1: false,
                isActive_2: false,
                isActive_3: false,
                isActiveHex: true,
                isActiveHex_1: false,
                isActiveHex_2: false,
                isActiveHex_3: false,
            },
            users: [
                {
                    id: 1,
                    firstName: 'Ann',
                    lastName: 'Brain',
                    email: 'Ann@mail.ru',
                    password: 'Anna12',
                    roleUser: 'user',
                },
                {
                    id: 2,
                    firstName: 'Peter',
                    lastName: 'Bud',
                    email: 'Peter@mail.ru',
                    password: 'Peter12',
                    roleUser: 'user',
                },
                {
                    id: 3,
                    firstName: 'Danil',
                    lastName: 'Petrov',
                    email: 'Danil@mail.ru',
                    password: 'Danil12',
                    roleUser: 'admin',
                }
            ],
            loginIn: {
                isLogin: false,
                nameUser: '',
                firstName: '',
                lastName: '',
            },
        }
    },
    getters: {},
    mutations: {
        loginSet(state, user) {
            state.loginIn.isLogin = true
            state.loginIn.firstName = user.firstName
            state.loginIn.lastName = user.lastName
            state.loginIn.nameUser = `${user.firstName}${user.lastName}`
        },
        loginRemove(state) {
            state.loginIn.isLogin = false
            state.loginIn.firstName = ''
            state.loginIn.lastName = ''
            state.loginIn.nameUser = ''
        },
        changeActive(state, isAct) {
            Object.keys(state.activeList).forEach(el => state.activeList[el] = false)
            state.activeList[isAct] = true
        },
        changeShowhexaNav(state, isShowNav) {
            Object.keys(state.isShow).forEach(el => state.isShow[el] = false)
            Object.keys(state.activeList).forEach(el => state.activeList[el] = false)
            if (isShowNav === 'ShowHexaNavigation') state.activeList.isActiveHex = true
            if (isShowNav === 'ShowKeeperNav') state.activeList.isActive = true

            state.isShow[isShowNav] = true
            localStorage.setItem('showNav',JSON.stringify(state.isShow))
        },
        removeNav(state) {
            Object.keys(state.isShow).forEach(el => state.isShow[el] = false)
            localStorage.setItem('showNav',JSON.stringify(state.isShow))
        },
        addShowNav(state, obj) {
            state.isShow = obj
        },
    },
    actions: {
        getUser({commit}) {
            let user = JSON.parse(localStorage.getItem('DanilPetrov'))
            if (user) {
                commit('loginSet', user)
            }
        },
        setUser({state}) {
            localStorage.setItem('DanilPetrov', JSON.stringify(state.loginIn))
        },
        removeUser({commit}) {
            localStorage.removeItem('DanilPetrov')
            commit('loginRemove')
        },
        getShowNav({commit}) {
            let obj = JSON.parse(localStorage.getItem('showNav'))
            if (obj) {
                commit('addShowNav', obj )
            }

        },
    },
    namespaced: true,
}