<template>
  <NavBar/>
  <div class="my_container">
    <router-view/>
  </div>

</template>

<script>
import NavBar from "@/components/NavBar";
import {mapState, mapMutations, mapActions} from 'vuex';

export default {
  name: 'App',
  components: {
    NavBar,
  },
  computed: {
    ...mapState({
      isShow: state => state.General.isShow,
    }),
  },
  methods: {
    ...mapMutations({
      initialState: "hexagram/initialState",
    }),
    ...mapActions({
      getUser: "General/getUser",
      getShowNav: "General/getShowNav",
    }),
  },
  mounted() {
    this.getShowNav()
    this.initialState()
  },
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

}
.my_container {
  padding-top: 70px;
}
</style>
