<template>
  <div class="container">
    <h1 class="text-center mt-2">Книга Перемен</h1>
    <div class="text-center my-3">древнекитайский философский трактат, предназначенный для гадания.</div>
  </div>
</template>

<script>
export default {
  name: "PredHome"
}
</script>

<style scoped>

</style>